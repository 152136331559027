import React, { useCallback, useEffect, useMemo } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import {
  Box,
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BoxSearchWrapper,
  StyledBadge,
  StyledRightActionButton,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import TablePagination from "@/components/TablePagination";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import { FileRecord, ProjectRecord } from "@/types";
import { createFileData } from "@/utils/createFileData.ts";
import FileTableItem from "@/components/FileTableItem";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import {
  COMMON_SORT_ITEMS,
  FilesManagementCSVHeaders,
  PROJECT_FILE_STATUSES,
  TEMPLATE_TYPES_FILTER_ITEMS,
} from "@/constants";
import { getProjectDocumentationBadgeVariant } from "@/utils/getProjectDocumentationBadgeVariant.ts";
import { useAuthStore } from "@/store/authStore.ts";
import { updateProjectFileReq, uploadProjectFileReq } from "@/api/projectsApi";
import FileManagementRowActions from "@/pages/admin-pages/FileManagementPage/copmonents/FileManagement/FileManagementRowActions.tsx";
import TableFilter from "@/components/TableFilter";
import useTableFilter from "@/hooks/useTableFilter.ts";
import GeneralCheckbox from "@/pages/ProjectsPage/components/GeneralCheckbox.tsx";
import RowCheckbox from "@/pages/ProjectsPage/components/RowCheckbox.tsx";
import FilesSelectionInfo from "@/pages/admin-pages/FileManagementPage/copmonents/FileManagement/FilesSelectionInfo.tsx";
import CommonTableActions from "@/components/CommonTableActions";
import TableSort from "@/components/TableSort";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import UploadProjectFile from "@/components/UploadProjectFile";
import SVGDownload from "@/assets/icons/SVGDownload.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import { getFileTypeLabel } from "@/utils/getFileTypeLabel.ts";

const PROJECT_FILES_TABLE_ID = "PROJECT_FILES_TABLE_ID";

const FileManagementTable = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const role = useAuthStore((state) => state.role);

  const { allUploadedFiles, totalUploadedFiles } = useAdminStore(
    (state) => state.allUploadedFiles,
  );
  const getAllUploadedFiles = useAdminStore(
    (state) => state.getAllUploadedFiles,
  );
  const rows = useMemo(() => {
    return allUploadedFiles?.map(
      ({
        id,
        fileName,
        size,
        createdAt,
        status,
        type,
        accountName,
        accountEmail,
        url,
      }) =>
        createFileData(
          id,
          fileName,
          size,
          createdAt,
          status,
          type,
          accountName ?? "",
          accountEmail,
          url,
        ),
    );
  }, [allUploadedFiles]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalUploadedFiles);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { dateRangeValue, handleDateRangeChange } = useDateRange();
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);

  const getFilesReq = useCallback(() => {
    fetchDataWithLoading(getAllUploadedFiles, [
      page + 1,
      rowsPerPage,
      searchValue,
      sort,
      filters,
      dateRangeValue,
    ]);
  }, [
    fetchDataWithLoading,
    getAllUploadedFiles,
    page,
    rowsPerPage,
    searchValue,
    sort,
    filters,
    dateRangeValue,
  ]);

  useEffect(() => {
    getFilesReq();
  }, [getFilesReq]);

  const changeFileStatus = async (
    fileId: FileRecord["id"],
    data: Partial<FileRecord>,
  ) => {
    try {
      await updateProjectFileReq(fileId, data);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };
  const uploadFile = async (data: FormData, id?: ProjectRecord["id"]) => {
    try {
      if (id) {
        await uploadProjectFileReq(id, data);
        getFilesReq();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StyledTableCard sx={{ width: "100%" }}>
      <CommonTableActions
        title={"File storage"}
        onSearch={handleDebounceSearchValue}
        onDateRange={handleDateRangeChange}
        filter={
          <TableFilter
            placeholder={"Types"}
            items={TEMPLATE_TYPES_FILTER_ITEMS}
            setFilterValues={handleFilterChange}
          />
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
        rightActions={
          <BoxSearchWrapper sx={{ justifyContent: "flex-end" }}>
            <StyledRightActionButton
              sx={{ whiteSpace: "nowrap", gap: "6px" }}
              onClick={() => {
                downloadCSV(
                  transformLogToCSV(allUploadedFiles as any),
                  "files",
                  FilesManagementCSVHeaders,
                );
              }}
              variant={"outlined"}
            >
              <SVGDownload />
              {!downSm && "Download CSV report"}
            </StyledRightActionButton>
            {uploadFile && role === "administrator" && (
              <UploadProjectFile
                showProjectSelect={true}
                isButton={true}
                uploadFile={uploadFile}
              />
            )}
          </BoxSearchWrapper>
        }
      />
      <FilesSelectionInfo
        search={searchValue}
        filter={filters}
        dateRangeValue={dateRangeValue}
        tableId={PROJECT_FILES_TABLE_ID}
      />
      {downSm && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 24px",
          }}
        >
          <GeneralCheckbox tableId={PROJECT_FILES_TABLE_ID} /> Select all{" "}
        </Box>
      )}
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <GeneralCheckbox tableId={PROJECT_FILES_TABLE_ID} />
              </StyledTableCell>
              <StyledTableCell>File name</StyledTableCell>
              <StyledTableCell>File size</StyledTableCell>
              <StyledTableCell>File type</StyledTableCell>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>Account email</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Upload date</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  showCheckbox
                  rowId={row.id}
                  key={row.id}
                  title={row.fileName}
                  firstSub={getFileTypeLabel(row.fileType)}
                  tableId={PROJECT_FILES_TABLE_ID}
                  secondSubSx={{ maxWidth: "185px" }}
                  secondSub={
                    <>
                      {row.status !== "deleted" && (
                        <BadgeSelect
                          readOnly={role !== "administrator"}
                          currentSelectedItem={row.status}
                          items={PROJECT_FILE_STATUSES}
                          onSelectItem={(value) =>
                            changeFileStatus(row.id, { status: value })
                          }
                          badgeVariantFn={getProjectDocumentationBadgeVariant}
                        />
                      )}
                      {row.status === "deleted" && (
                        <StyledBadge
                          sx={{ display: "inline", textWrap: "nowrap" }}
                          {...getProjectDocumentationBadgeVariant(row.status)}
                        >
                          {capitalizeFirstLetter(row.status)}
                        </StyledBadge>
                      )}
                    </>
                  }
                >
                  <CellWithHeader header={"File size"}>
                    {row.fileSize}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Name"}>
                    {row.accountName}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Email"}>
                    {row.accountEmail}
                  </CellWithHeader>
                  <CellWithHeader header={"Upload date"}>
                    {formatDate(row.createdAt)}
                  </CellWithHeader>
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell padding="checkbox">
                    <RowCheckbox
                      tableId={PROJECT_FILES_TABLE_ID}
                      rowId={row.id}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FileTableItem
                      url={`project/document/download/${row.id}`}
                      fileName={row.fileName}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.fileSize}</StyledTableCell>
                  <StyledTableCell>
                    {getFileTypeLabel(row.fileType)}
                  </StyledTableCell>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.accountEmail}</StyledTableCell>
                  <StyledTableCell>
                    {row.status !== "deleted" && (
                      <BadgeSelect
                        readOnly={role !== "administrator"}
                        currentSelectedItem={row.status}
                        items={PROJECT_FILE_STATUSES}
                        onSelectItem={(value) =>
                          changeFileStatus(row.id, { status: value })
                        }
                        badgeVariantFn={getProjectDocumentationBadgeVariant}
                      />
                    )}
                    {row.status === "deleted" && (
                      <StyledBadge
                        sx={{ display: "inline", textWrap: "nowrap" }}
                        {...getProjectDocumentationBadgeVariant(row.status)}
                      >
                        {capitalizeFirstLetter(row.status)}
                      </StyledBadge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{formatDate(row.createdAt)}</StyledTableCell>
                  <StyledTableCell>
                    <StyledRowActionsWrapperBox>
                      {role && (
                        <FileManagementRowActions
                          status={row.status}
                          rowId={row.id}
                          getFilesReq={getFilesReq}
                        />
                      )}
                    </StyledRowActionsWrapperBox>
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalUploadedFiles}
      />
    </StyledTableCard>
  );
};

export default FileManagementTable;
