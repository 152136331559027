import React from "react";
import Layout from "@/components/Layout";
import { Box, styled, Typography } from "@mui/material";

const ContentBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const NotFoundPage = () => {
  return (
    <Layout
      sxMainWrapper={{ height: "100vh" }}
      sxBoxContent={{ height: "100%" }}
    >
      <ContentBox>
        <Typography sx={{ fontSize: "40px" }}>404</Typography>
        <Typography sx={{ fontSize: "40px", textAlign: "center" }}>
          Oops! The page you’re looking for doesn’t exist.
        </Typography>
      </ContentBox>
    </Layout>
  );
};

export default NotFoundPage;
