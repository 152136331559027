import { Box, styled, SxProps } from "@mui/material";
import Header from "@/components/Header";
import React, { ReactNode } from "react";
import ProgressBar from "@/components/ProgressBar";

const BoxLayout = styled(Box)(() => ({
  backgroundColor: "#F4F4F7",
  width: "100%",
  minHeight: "100vh",
}));

const BoxMainWrapper = styled(Box)(() => ({
  flexDirection: "column",
  width: "100%",
  maxHeight: "100vh",
  overflowY: "auto",
  display: "flex",
}));

const BoxContent = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  display: "flex",
  width: "100%",
  maxHeight: "100vh",
  overflowX: "auto",
  padding: "0 80px",
  position: "relative",
  zIndex: 10,
  [theme.breakpoints.down("xl")]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 24px",
  },
}));

type Props = {
  headerBottomSection?: ReactNode;
  children: ReactNode;
  sxMainWrapper?: SxProps;
  sxBoxContent?: SxProps;
};

const Layout = ({
  children,
  headerBottomSection,
  sxMainWrapper,
  sxBoxContent,
}: Props) => {
  return (
    <BoxLayout>
      <BoxMainWrapper sx={{ ...sxMainWrapper }}>
        <Header bottomSection={headerBottomSection} />
        <BoxContent sx={{ ...sxBoxContent }}>
          <ProgressBar />
          {children}
        </BoxContent>
      </BoxMainWrapper>
    </BoxLayout>
  );
};

export default Layout;
