import React from "react";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { StyledIconButton } from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import Modal from "@/components/Modal";
import { Control, FormProvider, UseFormReturn } from "react-hook-form";
import FirstUserForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/FirstUserForm.tsx";
import SecondContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondContractorForm.tsx";
import ThirdContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/ThirdContractorForm.tsx";
import SecondOemForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondOemForm.tsx";
import { UserType } from "@/types";
import SvgUserEdit from "@/assets/icons/SVGUserEdit.tsx";

type Props = {
  methods: UseFormReturn<Partial<UserType>, any, undefined>;
  user: UserType;
  control: Control<Partial<UserType>, any>;
  handleClose: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
  selectedRole: UserType["role"] | string | undefined;
  isDisabled: boolean;
  iconOnly?: boolean;
};

const UpdateUserModal = ({
  setOpen,
  handleClose,
  user,
  methods,
  onSubmit,
  open,
  control,
  selectedRole,
  isDisabled,
  iconOnly = false,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <StyledIconButton
        sx={{ ...(downSm && { flex: 1 }) }}
        onClick={() => setOpen(true)}
        variant={downSm ? "outlined" : "text"}
      >
        {!downSm && <SvgUserEdit />}
        {iconOnly && downSm && <SvgUserEdit />}
        {downSm && !iconOnly && "Edit"}
      </StyledIconButton>
      <Modal open={open} onClose={handleClose} title={"Edit Account"}>
        <FormProvider {...methods}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              marginTop: "24px",
            }}
            onSubmit={onSubmit}
          >
            <FirstUserForm isEdit={true} control={control} />
            {selectedRole === "contractor" && (
              <SecondContractorForm control={control} />
            )}
            {selectedRole === "contractor" && (
              <ThirdContractorForm
                license={user?.license}
                certification={user?.certification}
                control={control}
              />
            )}
            {selectedRole === "oem" && (
              <SecondOemForm isEdit={true} control={control} />
            )}
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                gap: "8px",
                width: "100%",
              }}
            >
              <Button
                variant={"outlined"}
                sx={{ width: "100%" }}
                onClick={() => handleClose()}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type={"submit"}
                disabled={isDisabled}
              >
                Update
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default UpdateUserModal;
