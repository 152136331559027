import React, { useCallback, useEffect, useMemo, useState } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import {
  Box,
  Modal as MuiModal,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledPinnedCell,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableCellTruncated,
  StyledTableHeader,
  StyledTableRow,
  StyledTooltip,
} from "@/components/styled";
import TablePagination from "@/components/TablePagination";
import { getEnrollmentBadgeVariant } from "@/utils/getEnrollmentBadgeVariant.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import { createSearchParams, useNavigate } from "react-router-dom";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { useProjectsStore } from "@/store/projectsStore.ts";
import { FileRecord, ProjectRecord } from "@/types";
import DangerModal from "@/components/DangerModal";
import {
  archiveProjectReq,
  restoreProjectReq,
  updateProject,
} from "@/api/projectsApi";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import {
  CONTRACTOR_ENROLLMENT_STATUS_ITEMS,
  INCENTIVE_STATUS,
  INCENTIVE_STATUS_ITEMS,
  LAST_UPDATED_SORT_ITEMS,
  PROJECTS_FILTER_ITEMS,
} from "@/constants";
import { useAuthStore } from "@/store/authStore.ts";
import { formatDate } from "@/utils/formatDate.ts";
import { removeLeadingZero } from "@/utils/removeLeadingZero.ts";
import IncentiveStatusSelect from "@/pages/ProjectsPage/components/IncentiveStatusSelect.tsx";
import RejectedProjectModal from "@/pages/ProjectsPage/components/RejectedProjectModal.tsx";
import GeneralCheckbox from "@/pages/ProjectsPage/components/GeneralCheckbox.tsx";
import RowCheckbox from "@/pages/ProjectsPage/components/RowCheckbox.tsx";
import ProjectsSelectedInfo from "@/pages/ProjectsPage/components/ProjectsSelectedInfo.tsx";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import CorrectionNeededModal from "@/pages/ProjectsPage/components/CorrectionNeededModal.tsx";
import TableFilter from "@/components/TableFilter";
import CommonTableActions from "@/components/CommonTableActions";
import AddProjectModal from "@/pages/ProjectDetailsPage/components/AddProjectModal.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import ProjectRowActions from "@/pages/ProjectsPage/components/ProjectRowActions.tsx";
import PtoLetterCheckbox from "@/pages/ProjectsPage/components/PTOLetterCheckbox.tsx";
import { calculateUsableBatteryCapacity } from "@/utils/calculateUsableBatteryCapacity.ts";
import {
  getEnrollmentStatusChangeAllowed,
  getEnrollmentStatusItems,
} from "@/utils/contractorEnrollmentStatusHelpers.ts";
import { useApplicationStore } from "@/store/applicationStore.ts";
import { getStatusTooltipContent } from "@/utils/getStatusTooltipContent.ts";
import TableSort from "@/components/TableSort";
import useTableSort from "@/hooks/useTableSort.ts";

function createData(
  project: Partial<ProjectRecord>,
  id: number,
  customerName: string,
  address: string,
  incentiveStatus: INCENTIVE_STATUS | undefined,
  enrolmentStatus: ProjectRecord["enrolmentStatus"],
  contractor: string | null,
  batteryOem: string | null,
  batteryModel: string,
  batteryType: string,
  usableCapacity: string,
  upfrontIncentivePayment: string,
  participantEnrollment: FileRecord,
  systemSizingTool: FileRecord,
  contract: FileRecord,
  createdAt: string,
  updatedAt: string,
  latestEnrolmentUpdate: string | null,
  latestIncentiveUpdate: string | null,
) {
  return {
    ...(project || {}),
    id,
    customerName,
    address,
    incentiveStatus,
    enrolmentStatus,
    contractor,
    batteryOem,
    batteryModel,
    batteryType,
    usableCapacity,
    upfrontIncentivePayment,
    participantEnrollment,
    systemSizingTool,
    contract,
    createdAt: formatDate(createdAt),
    updatedAt: formatDate(updatedAt),
    latestEnrolmentUpdate: latestEnrolmentUpdate
      ? formatDate(latestEnrolmentUpdate)
      : null,
    latestIncentiveUpdate: latestIncentiveUpdate
      ? formatDate(latestIncentiveUpdate)
      : null,
  };
}

const PROJECTS_TABLE_ID = "PROJECTS_TABLE_ID";

const ProjectsTable = () => {
  const isApplicationsActive = useApplicationStore((state) => state.isActive);
  const navigate = useNavigate();
  const [yScroll, setYScroll] = useState(0);
  const [deleteProjectId, setDeleteProjectId] = useState(0);
  const [rejectProjectId, setRejectProjectId] = useState(0);
  const [correctionNeededProjectId, setCorrectionNeededProjectId] = useState(0);
  const [restoreProjectId, setRestoreProjectId] = useState(0);
  const [isPTOLetterFilter, setPTOLetterFilter] = useState(false);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchDataWithLoading = useFetchDataWithLoading();

  const role = useAuthStore((state) => state.role);

  const { allProjects, totalProjectsCount } = useProjectsStore(
    (state) => state.allProjects,
  );
  const getAllProjects = useProjectsStore((state) => state.getAllProjects);
  const rows = useMemo(() => {
    return allProjects?.map(
      ({
        id,
        customerName,
        address,
        incentiveStatus,
        enrolmentStatus,
        contractor,
        batteryOem,
        batteryModel,
        batteryType,
        usableCapacity,
        upfrontIncentivePayment,
        participantEnrollment,
        systemSizingTool,
        contract,
        createdAt,
        updatedAt,
        latestIncentiveUpdate,
        latestEnrolmentUpdate,
        ...project
      }) =>
        createData(
          project,
          id,
          customerName,
          address,
          incentiveStatus,
          enrolmentStatus,
          contractor,
          batteryOem,
          batteryModel,
          batteryType,
          usableCapacity,
          upfrontIncentivePayment,
          participantEnrollment,
          systemSizingTool,
          contract,
          createdAt,
          updatedAt,
          latestEnrolmentUpdate,
          latestIncentiveUpdate,
        ),
    );
  }, [allProjects]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalProjectsCount);
  const {
    searchValue,
    handleDebounceSearchValue: originalHandleDebounceSearchValue,
  } = useDebouncedSearch(handleChangePage);

  const handleDebounceSearchValue = (value: string) => {
    const sanitizedValue = removeLeadingZero(value);
    originalHandleDebounceSearchValue(sanitizedValue);
  };

  const { sort, handleSortChange } = useTableSort();

  const {
    filters: enrollmentFilters,
    handleFilterChange: handleFilterChangeEnrollment,
  } = useTableFilter(handleChangePage);
  const {
    filters: incentiveFilters,
    handleFilterChange: handleFilterChangeIncentive,
  } = useTableFilter(handleChangePage);

  const filters = useMemo(() => {
    return [...enrollmentFilters, ...incentiveFilters];
  }, [enrollmentFilters, incentiveFilters]);

  const getProjectsReq = useCallback(() => {
    fetchDataWithLoading(getAllProjects, [
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      isPTOLetterFilter,
      sort,
    ]);
  }, [
    fetchDataWithLoading,
    getAllProjects,
    page,
    rowsPerPage,
    searchValue,
    filters,
    isPTOLetterFilter,
    sort,
  ]);

  useEffect(() => {
    getProjectsReq();
  }, [getProjectsReq]);

  const handleCloseModal = () => setDeleteProjectId(0);
  const handleCloseRestoreModal = () => setRestoreProjectId(0);

  const togglePTOLetterFilter = () => setPTOLetterFilter((prev) => !prev);

  const handleArchiveProject = async () => {
    try {
      await archiveProjectReq(deleteProjectId);
      setDeleteProjectId(0);
      getProjectsReq();
    } catch (e) {
      console.error(e);
    }
  };

  const handleRestoreProject = async () => {
    try {
      await restoreProjectReq(restoreProjectId);
      setRestoreProjectId(0);
      getProjectsReq();
    } catch (e) {
      console.error(e);
    }
  };

  const updateEnrolmentStatus = async (
    projectId: ProjectRecord["id"],
    enrolmentStatus: string,
  ) => {
    try {
      switch (enrolmentStatus) {
        case "rejected": {
          setRejectProjectId(projectId);
          break;
        }
        case "correction_needed": {
          setCorrectionNeededProjectId(projectId);
          break;
        }
        default: {
          const formData = new FormData();
          formData.append("enrolmentStatus", enrolmentStatus as string);
          await updateProject(projectId, formData);
          getProjectsReq();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const navigateToProject = (rowId: number) => {
    const params = createSearchParams({
      ...(searchValue && { search: searchValue }),
      ...(filters.length && { filter: filters.join(",") }),
      ...(isPTOLetterFilter && { pto: `${isPTOLetterFilter}` }),
    });
    navigate({
      pathname: `/projects/${rowId}`,
      search: params?.toString(),
    });
  };

  const isAdmin = role === "administrator";
  const isOem = role === "oem";
  const isContractor = role === "contractor";

  useEffect(() => {
    setYScroll(window.innerHeight - 400);
  }, []);

  const showCreateProjectButton = isApplicationsActive && !isOem;

  return (
    <StyledTableCard sx={{ width: "100%" }}>
      {!isApplicationsActive && isApplicationsActive != null && (
        <Typography sx={{ padding: "12px", textAlign: "center" }}>
          We are currently not accepting new applications. Please follow our
          news for updates.
        </Typography>
      )}
      <CommonTableActions
        title={"Projects"}
        onSearch={handleDebounceSearchValue}
        sort={
          <>
            <TableSort
              placeholder={"Last updated"}
              items={LAST_UPDATED_SORT_ITEMS}
              setSortValue={handleSortChange}
            />
            {!downSm && isAdmin && (
              <PtoLetterCheckbox
                isPTOLetterFilter={isPTOLetterFilter}
                togglePTOLetterFilter={togglePTOLetterFilter}
              />
            )}
          </>
        }
        filter={
          <>
            <TableFilter
              placeholder={"Incentive"}
              items={INCENTIVE_STATUS_ITEMS}
              setFilterValues={handleFilterChangeIncentive}
            />
            <TableFilter
              placeholder={"Enrollment"}
              items={PROJECTS_FILTER_ITEMS}
              setFilterValues={handleFilterChangeEnrollment}
            />
          </>
        }
        rightActions={
          showCreateProjectButton ? (
            <AddProjectModal getProjectsReq={getProjectsReq} />
          ) : null
        }
      />
      {downSm && isAdmin && (
        <PtoLetterCheckbox
          isPTOLetterFilter={isPTOLetterFilter}
          togglePTOLetterFilter={togglePTOLetterFilter}
        />
      )}
      <ProjectsSelectedInfo
        pto={isPTOLetterFilter}
        search={searchValue}
        filter={filters}
        tableId={PROJECTS_TABLE_ID}
      />
      {downSm && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 24px 10px 10px",
          }}
        >
          <GeneralCheckbox tableId={PROJECTS_TABLE_ID} /> Select all{" "}
        </Box>
      )}
      <TableContainer sx={{ maxHeight: yScroll }}>
        <StyledTable stickyHeader aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <GeneralCheckbox tableId={PROJECTS_TABLE_ID} />
              </StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Customer name</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Incentive status</StyledTableCell>
              <StyledTableCell>Enrollment status</StyledTableCell>
              <StyledTableCell>Contractor</StyledTableCell>
              <StyledTableCell>Battery OEM</StyledTableCell>
              <StyledTableCell>Battery Model</StyledTableCell>
              <StyledTableCell>Battery Capacity (kWh)</StyledTableCell>
              <StyledTableCell>Upfront Incentive Payment</StyledTableCell>
              <StyledTableCell>Notes</StyledTableCell>
              {isAdmin && <StyledTableCell>Last Updated</StyledTableCell>}
              <StyledTableCell sx={{ minWidth: "150px" }}></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  showCheckbox
                  rowId={row.id}
                  key={row.id}
                  title={row.customerName}
                  navigateTo={() => navigateToProject(row.id)}
                  firstSub={row?.id?.toString()?.padStart(3, "0")}
                  tableId={PROJECTS_TABLE_ID}
                  secondSub={
                    <>
                      <IncentiveStatusSelect
                        tooltipContent={""}
                        truncateBadge={downSm}
                        projectId={row?.id}
                        incentiveStatus={row?.incentiveStatus}
                        getProjectsReq={getProjectsReq}
                        upfrontIncentivePayment={row.upfrontIncentivePayment}
                      />
                      {!isContractor && (
                        <BadgeSelect
                          truncateBadge={downSm}
                          readOnly={role !== "administrator"}
                          currentSelectedItem={row.enrolmentStatus as string}
                          items={PROJECTS_FILTER_ITEMS}
                          onSelectItem={(value) =>
                            updateEnrolmentStatus(row.id, value)
                          }
                          badgeVariantFn={getEnrollmentBadgeVariant}
                        />
                      )}
                      {isContractor && (
                        <BadgeSelect
                          truncateBadge={downSm}
                          readOnly={row.enrolmentStatus !== "correction_needed"}
                          currentSelectedItem={row.enrolmentStatus as string}
                          items={CONTRACTOR_ENROLLMENT_STATUS_ITEMS}
                          onSelectItem={(value) =>
                            updateEnrolmentStatus(row.id, value)
                          }
                          badgeVariantFn={getEnrollmentBadgeVariant}
                        />
                      )}
                    </>
                  }
                  secondSubSx={{ maxWidth: "210px" }}
                >
                  <StyledTooltip
                    title={row?.address?.length < 55 ? null : row.address}
                    enterDelay={500}
                  >
                    <StyledTableCellTruncated>
                      {row.address}
                    </StyledTableCellTruncated>
                  </StyledTooltip>
                  <CellWithHeader header={"Contractor"}>
                    {row.contractor}
                  </CellWithHeader>
                  <CellWithHeader header={"Battery model"}>
                    {row.batteryModel}
                  </CellWithHeader>
                  <CellWithHeader header={"Battery Capacity (kWh)"}>
                    {calculateUsableBatteryCapacity(
                      row.usableCapacity,
                      row.numberOfBatteries,
                    )}
                  </CellWithHeader>
                  <CellWithHeader header={"Upfront Incentive Payment"}>
                    {formatCurrency(Number(row.upfrontIncentivePayment) / 100)}
                  </CellWithHeader>
                  <CellWithHeader header={"Notes"}>
                    {row.comment?.note}
                  </CellWithHeader>
                  <ProjectRowActions
                    getProjectsReq={getProjectsReq}
                    row={row as ProjectRecord}
                    setDeleteProjectId={setDeleteProjectId}
                    setRestoreProjectId={setRestoreProjectId}
                  />
                </CollapsableRow>
              ) : (
                <StyledTableRow
                  onClick={() => {
                    navigateToProject(row.id);
                  }}
                  sx={{ cursor: "pointer" }}
                  key={row.id}
                >
                  <StyledTableCell padding="checkbox">
                    <RowCheckbox tableId={PROJECTS_TABLE_ID} rowId={row.id} />
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.id?.toString()?.padStart(3, "0")}
                  </StyledTableCell>
                  <StyledTableCell sx={{ cursor: "pointer" }}>
                    {row.customerName}
                  </StyledTableCell>
                  <StyledTooltip
                    title={row?.address?.length < 55 ? null : row.address}
                    enterDelay={500}
                  >
                    <StyledTableCellTruncated>
                      {row.address}
                    </StyledTableCellTruncated>
                  </StyledTooltip>
                  <StyledTableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IncentiveStatusSelect
                      tooltipContent={getStatusTooltipContent(
                        row?.latestIncentiveUpdate,
                        role,
                      )}
                      projectId={row?.id}
                      incentiveStatus={row?.incentiveStatus}
                      getProjectsReq={getProjectsReq}
                      upfrontIncentivePayment={row.upfrontIncentivePayment}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {!isContractor && (
                      <BadgeSelect
                        tooltipContent={getStatusTooltipContent(
                          row?.latestEnrolmentUpdate,
                          role,
                        )}
                        readOnly={role !== "administrator"}
                        currentSelectedItem={row.enrolmentStatus as string}
                        items={PROJECTS_FILTER_ITEMS}
                        onSelectItem={(value) =>
                          updateEnrolmentStatus(row.id, value)
                        }
                        badgeVariantFn={getEnrollmentBadgeVariant}
                      />
                    )}
                    {isContractor && (
                      <BadgeSelect
                        tooltipContent={getStatusTooltipContent(
                          row?.latestEnrolmentUpdate,
                          role,
                        )}
                        readOnly={getEnrollmentStatusChangeAllowed(
                          row.enrolmentStatus,
                        )}
                        currentSelectedItem={row.enrolmentStatus as string}
                        items={getEnrollmentStatusItems(row.enrolmentStatus)}
                        onSelectItem={(value) =>
                          updateEnrolmentStatus(row.id, value)
                        }
                        badgeVariantFn={getEnrollmentBadgeVariant}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.contractor}</StyledTableCell>
                  <StyledTableCell>{row.batteryOem}</StyledTableCell>
                  <StyledTableCell>{row.batteryModel}</StyledTableCell>
                  <StyledTableCell>
                    {calculateUsableBatteryCapacity(
                      row.usableCapacity,
                      row.numberOfBatteries,
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatCurrency(Number(row.upfrontIncentivePayment) / 100)}
                  </StyledTableCell>
                  <StyledTooltip
                    title={
                      Number(row?.comment?.note?.length) < 55
                        ? null
                        : row.comment?.note
                    }
                    enterDelay={500}
                  >
                    <StyledTableCellTruncated>
                      {row.comment?.note}
                    </StyledTableCellTruncated>
                  </StyledTooltip>
                  {isAdmin && (
                    <StyledTableCellTruncated>
                      {row.updatedAt}
                    </StyledTableCellTruncated>
                  )}
                  <StyledPinnedCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{ minWidth: "171px", minHeight: "92px" }}
                  >
                    <ProjectRowActions
                      getProjectsReq={getProjectsReq}
                      row={row as ProjectRecord}
                      setDeleteProjectId={setDeleteProjectId}
                      setRestoreProjectId={setRestoreProjectId}
                    />
                  </StyledPinnedCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalProjectsCount}
      />
      <MuiModal open={!!deleteProjectId} onClose={handleCloseModal}>
        <>
          <DangerModal
            onClick={handleArchiveProject}
            onClose={handleCloseModal}
            title={`Archive Project?`}
            subtitle={`You can restore it anytime.`}
            buttonText={"Archive"}
          />
        </>
      </MuiModal>
      <MuiModal open={!!restoreProjectId} onClose={handleCloseRestoreModal}>
        <>
          <DangerModal
            onClick={handleRestoreProject}
            onClose={handleCloseRestoreModal}
            title={`Restore Project?`}
            subtitle={`This action will restore the project to the active list.`}
            buttonText={"Restore"}
            variant={"yellow"}
          />
        </>
      </MuiModal>
      <RejectedProjectModal
        projectId={rejectProjectId}
        onClose={() => setRejectProjectId(0)}
        getProjectsReq={getProjectsReq}
      />
      <CorrectionNeededModal
        projectId={correctionNeededProjectId}
        onClose={() => setCorrectionNeededProjectId(0)}
        getProjectsReq={getProjectsReq}
      />
    </StyledTableCard>
  );
};

export default ProjectsTable;
