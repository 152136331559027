import {
  Box,
  FormControl,
  FormLabel,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { ReactNode, useState } from "react";
import { StyledInput } from "@/components/styled";
import SvgSelectArrowUp from "@/assets/icons/SVGSelectArrowUp.tsx";
import SvgSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";

type FormSelectProps = SelectProps & {
  name: string;
  control: Control<any, any>;
  controlProps?: Partial<ControllerProps>;
  label?: string;
  children: ReactNode;
};

const FormSelect = ({
  name,
  control,
  controlProps,
  label,
  children,
  ...props
}: FormSelectProps) => {
  const [open, setOpen] = useState(false);
  const isRequired = !!controlProps?.rules?.required;

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: "24px" }}
    >
      <FormLabel component="legend">
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: (theme) => theme.palette.textGray1,
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}{" "}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value } }) => (
          <>
            <Select
              sx={{ position: "relative" }}
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              value={value ?? ""}
              onChange={onChange}
              IconComponent={() => null}
              disabled={props?.disabled}
              renderValue={(selected) => {
                return <>{capitalizeFirstLetter(selected)}</>;
              }}
              input={<StyledInput active={open} />}
            >
              {children}
            </Select>
            <Box
              onClick={() => {
                if (!props?.disabled) {
                  setOpen(!open);
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                ...(!props?.disabled && { cursor: "pointer" }),
                position: "absolute",
                right: "16px",
                top: "50%",
                transform: "translateY(-100%)",
              }}
            >
              {open ? (
                <SvgSelectArrowUp color={"#0076CE"} />
              ) : (
                <SvgSelectArrowDown
                  color={props?.disabled ? "#00000061" : ""}
                />
              )}
            </Box>
            {!value && (
              <Box
                onClick={() => setOpen(!open)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "absolute",
                  left: "16px",
                  top: "50%",
                  transform: "translateY(-100%)",
                }}
              >
                <Typography variant={"subtitleRegular"}>
                  {props?.placeholder}
                </Typography>
              </Box>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default FormSelect;
