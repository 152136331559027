import SVGUserGroup from "@/assets/icons/SVGUserGroup.tsx";
import SVGBudgetManagement from "@/assets/icons/SVGBudgetManagement.tsx";
import SVGFileManagement from "@/assets/icons/SVGFileManagement.tsx";
import SVGEligibility from "@/assets/icons/SVGEligibility.tsx";
import SVGTraining from "@/assets/icons/SVGTraining.tsx";
import SVGData from "@/assets/icons/SVGData.tsx";
import SVGDataExchange from "@/assets/icons/SVGDataExchange.tsx";
import { StyledTableCell } from "@/components/styled";
import React from "react";
import { FileRecord } from "@/types";

export enum MonthsMap {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}
export enum DaysMap {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export enum PAGE_ROUTES {
  LOGIN = "/login",
  RESET_PASSWORD = "/password-reset",
  RESET_LINK = "/reset-link",
  VERIFICATION = "/verification",
  CONTRACTOR_DASHBOARD = "/contractor-dashboard",
  MAP = "/map",
  USER_MANAGEMENT = "/user-management",
  ROLES = "roles",
  ACCESS_LOGS = "access-logs",
  USER_PROFILE = "/user-profile",
  PRIVACY_POLICY = "/privacy-policy",
  ACCOUNT_LOCKED = "/account-locked",
  FILES_MANAGEMENT = "/files-management",
  TEMPLATES = "templates",
  USERS_FILES = "users",
  CONTRACTOR_FILES_MANAGEMENT = "/contractor-files-management",
  PROJECTS = "/projects",
  BUDGET = "/budget",
  UPDATES = "/updates",
  DATA_REQUESTS = "data-requests",
}

export const RowsPerPageOptions = [20, 40, 50, 100];

export const USERS_MANAGEMENT_TABS = [
  { text: "Accounts", navTo: `.` },
  { text: "Roles", navTo: `./roles` },
  { text: "Access Logs", navTo: `./access-logs` },
];

export const FILES_MANAGEMENT_TABS = [
  { text: "Projects", navTo: `.` },
  { text: "Templates", navTo: `./templates` },
  { text: "Accounts", navTo: `./users` },
];

export const PermissionCards = [
  {
    title: "Account Management",
    icon: <SVGUserGroup />,
    permissions: [
      {
        label: "View Account list",
        admin: true,
        contractor: false,
        oem: false,
      },
      { label: "Add new Account", admin: true, contractor: false, oem: false },
      { label: "Modify Account", admin: true, contractor: false, oem: false },
      { label: "Delete Account", admin: true, contractor: false, oem: false },
      {
        label: "View Unauthorized access list",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "Download Unauthorized access report",
        admin: true,
        contractor: false,
        oem: false,
      },
    ],
  },
  {
    title: "Budget Management",
    icon: <SVGBudgetManagement />,
    permissions: [
      {
        label: "View Budget Analysis",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "View Total Balance",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "View Latest Updates",
        admin: true,
        contractor: false,
        oem: false,
      },
    ],
  },
  {
    title: "File Management",
    icon: <SVGFileManagement />,
    permissions: [
      { label: "File Overview", admin: false, contractor: true, oem: true },
      { label: "Upload files", admin: false, contractor: true, oem: true },
      { label: "Download files", admin: false, contractor: true, oem: true },
      { label: "Delete files", admin: false, contractor: true, oem: false },
      {
        label: "View Activity Log",
        admin: false,
        contractor: true,
        oem: false,
      },
    ],
  },
  {
    title: "Eligibility & Incentive",
    icon: <SVGEligibility />,
    permissions: [
      { label: "Input data", admin: false, contractor: true, oem: false },
      {
        label: "View Eligibility Levels",
        admin: false,
        contractor: true,
        oem: false,
      },
      {
        label: "View Incentive Levels",
        admin: false,
        contractor: true,
        oem: false,
      },
    ],
  },
  {
    title: "Online Training",
    icon: <SVGTraining />,
    permissions: [
      {
        label: "View and Complete Training Content",
        admin: false,
        contractor: false,
        oem: false,
      },
      {
        label: "Authenticate Post-Training",
        admin: false,
        contractor: false,
        oem: false,
      },
      {
        label: "Track Training Progress",
        admin: false,
        contractor: false,
        oem: false,
      },
    ],
  },
  {
    title: "Data requests",
    icon: <SVGData />,
    permissions: [
      {
        label: "Send request for Data Anonymization",
        admin: false,
        contractor: true,
        oem: true,
      },
      {
        label: "Send request for Data Deletion",
        admin: false,
        contractor: true,
        oem: true,
      },
      {
        label: "Send request for Data Correction",
        admin: false,
        contractor: true,
        oem: true,
      },
      {
        label: "Allow Data Anonymization",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "Allow Data Deletion",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "Allow Data Correction",
        admin: true,
        contractor: false,
        oem: false,
      },
    ],
  },
  {
    title: "Secure Data Exchange",
    icon: <SVGDataExchange />,
    permissions: [
      {
        label: "Submission of quarterly performance data",
        admin: false,
        contractor: false,
        oem: true,
      },
      {
        label: "Submission of product updates",
        admin: false,
        contractor: false,
        oem: true,
      },
      { label: "Sending reports", admin: false, contractor: false, oem: true },
      {
        label: "View Backup Storage",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "View Documented Disaster Recovery Plan",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "Testing of Backup and Recovery Procedures",
        admin: true,
        contractor: false,
        oem: false,
      },
      {
        label: "Consent Management",
        admin: true,
        contractor: false,
        oem: false,
      },
    ],
  },
];

export const LogsCSVHeaders = [
  "Account id",
  "Account name",
  "IP Address",
  "Location",
  "Access type",
  "Result",
  "Time",
  "Event Log",
];

export const DataRequestLogsCSVHeaders = [
  "Account id",
  "Time",
  "Status",
  "Account name",
  "Email",
  "Log Type",
  "Location",
  "IP Address",
  "Description",
  "User ID",
  "Role",
];

export const FilesManagementCSVHeaders = [
  "File name",
  "File size",
  "File type",
  "Account name",
  "Account email",
  "Status",
  "Upload date",
];

export const TransactionsCSVHeaders = [
  "ID",
  "Date & Time",
  "Transaction type",
  "From",
  "To",
  "Amount of funds",
  "Email",
  "Note",
];

export const ProjectsCSVHeaders = [
  "ID",
  "Customer name",
  "Address",
  "City",
  "Zip code",
  "Account #",
  "Customer email",
  "Existing Inverter",
  "Existing Solar kW-DC",
  "Existing Solar kW-AC",
  "Existing Solar Annual Production",
  "New Solar kW-DC",
  "New Solar kW-AC",
  "New Solar Annual Production",
  "Incentive status",
  "Enrollment status",
  "Upfront Incentive Payment",
  "Contractor",
  "Battery Manufacturer",
  "Battery Product",
  "Nameplate Usable Capacity (kWh)",
  "Number of batteries",
  "Summer Enrollment %",
  "Winter Enrollment %",
  "Participation",
  "Created At",
];

export const ROLES_FILTER_ITEMS = [
  { value: "administrator", label: "Administrator", color: "#B53700" },
  { value: "contractor", label: "Contractor", color: "#007586" },
  { value: "oem", label: "OEM", color: "#005492" },
];

export const TEMPLATE_TYPES_FILTER_ITEMS = [
  { value: "Contract", label: "Contract" },
  { value: "Participant Enrollment", label: "Participant Enrollment" },
  { value: "Serial number/OEM ID", label: "Serial number/OEM ID" },
  { value: "PTO Letter", label: "PTO Letter" },
  { value: "Change Order", label: "Change Order" },
  { value: "Extension", label: "Extension" },
  { value: "Performance report", label: "Performance report" },
  { value: "other", label: "Other" },
];

export const LOGS_FILTER_ITEMS = [
  { value: "401", label: "Denied", color: "#B56C00" },
  { value: "200", label: "Success", color: "#007586" },
  { value: "400", label: "Failed", color: "#B53700" },
];

export const REQUEST_LOGS_FILTER_ITEMS = [
  { value: "projects", label: "Projects" },
  { value: "files", label: "Files" },
];

export const SESSIONS_FILTER_ITEMS = [
  { value: "ok", label: "Ok", color: "#007586" },
  { value: "error", label: "Error", color: "#B53700" },
];
export const PROJECTS_FILTER_ITEMS = [
  { value: "rejected", label: "Rejected", color: "#B53700" },
  { value: "pending", label: "Pending", color: "#B56C00" },
  { value: "active", label: "Active", color: "#007586" },
  {
    value: "correction_needed",
    label: "Correction needed",
    color: "#B56C00",
  },
  {
    value: "contractor_updated",
    label: "Contractor updated",
    color: "#616263",
  },
];

export const CONTRACTOR_ENROLLMENT_STATUS_ITEMS = [
  { value: "rejected", label: "Rejected", color: "#B53700", disabled: true },
  { value: "pending", label: "Pending", color: "#B56C00", disabled: true },
  { value: "active", label: "Active", color: "#007586", disabled: true },
  {
    value: "correction_needed",
    label: "Correction needed",
    color: "#B56C00",
  },
  {
    value: "contractor_updated",
    label: "Contractor updated",
    color: "#616263",
  },
];

export const PROJECTS_DOCUMENTATION_FILTER_ITEMS = [
  { value: "rejected", label: "Rejected", color: "#B53700" },
  { value: "pending_review", label: "Pending review", color: "#0076CE" },
  { value: "approved", label: "Approved", color: "#007586" },
];
export const UPLOADED_FILES_FILTER_ITEMS = [
  { value: "rejected", label: "Rejected", color: "#B53700" },
  { value: "pending", label: "Pending", color: "#0076CE" },
  { value: "approved", label: "Approved", color: "#007586" },
];

export const USERS_SORT_ITEMS = [
  {
    value: "createdAtDesc",
    label: "Creation: New to old (Default)",
  },
  { value: "createdAtAsc", label: "Creation: Old to new" },
  { value: "lastLoginDesc", label: "Last login: New to old " },
  { value: "lastLoginAsc", label: "Last login: Old to new" },
];

export const COMMON_SORT_ITEMS = [
  {
    value: "createdAtDesc",
    label: "New to old (Default)",
  },
  { value: "createdAtAsc", label: "Old to new" },
];

export const LAST_UPDATED_SORT_ITEMS = [
  {
    value: "updatedAtDesc",
    label: "From recent to oldest",
  },
  { value: "updatedAtAsc", label: "From oldest to recent" },
];

export const ADMIN_NAV_ITEMS = [
  { navTo: "/", label: "Budget" },
  { navTo: "/user-management", label: "Accounts" },
  // { navTo: "/backups", label: "Backups and Recovery" },
  { navTo: "/files-management", label: "Files" },
  { navTo: "/projects", label: "Projects" },
  { navTo: "/data-requests", label: "Data Requests" },
  { navTo: "/updates", label: "Updates" },
];

export const CONTRACTOR_NAV_ITEMS = [
  { navTo: "/map", label: "Map" },
  { navTo: "/files-management", label: "Files" },
  { navTo: "/projects", label: "Projects" },
  { navTo: "/budget", label: "Budget" },
  { navTo: "/updates", label: "Updates" },
];
export const OEM_NAV_ITEMS = [
  { navTo: "/files-management", label: "Files" },
  { navTo: "/projects", label: "Projects" },
  { navTo: "/budget", label: "Budget" },
  { navTo: "/updates", label: "Updates" },
];

export const AnalysisDataTypeItems = [
  { label: "Yearly", value: "yearly" },
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
];

export enum INCENTIVE_STATUS {
  PAID = "upfront_paid",
  RESERVED = "upfront_reserved",
  EXTENDED = "upfront_extended",
  PENDING = "pending_review",
  NO_HELD = "no_incentive_held",
  CANCELLED = "cancelled",
}

export const INCENTIVE_STATUS_ITEMS = [
  { value: INCENTIVE_STATUS.PAID, label: "Upfront-Paid", color: "#0076CE" },
  {
    value: INCENTIVE_STATUS.RESERVED,
    label: "Upfront-Reserved",
    color: "#B53700",
  },
  {
    value: INCENTIVE_STATUS.EXTENDED,
    label: "Upfront-Extended",
    color: "#007586",
  },
  {
    value: INCENTIVE_STATUS.PENDING,
    label: "Pending review",
    color: "#B56C00",
  },
  {
    value: INCENTIVE_STATUS.NO_HELD,
    label: "No incentive held",
    color: "#616263",
  },
  {
    value: INCENTIVE_STATUS.CANCELLED,
    label: "Cancelled",
    color: "#616263",
  },
];

export const PROJECT_FILE_STATUSES = [
  { value: "denied", label: "Denied", color: "#B53700" },
  { value: "approved", label: "Approved", color: "#007586" },
  { value: "ready_for_review", label: "Ready for review", color: "#0076CE" },
  { value: "pending", label: "Pending", color: "#B56C00" },
];

export const FILE_TYPES = {
  CONTRACT: "Contract",
  PARTICIPANT_ENROLLMENT: "Participant Enrollment",
  SERIAL_NUMBER: "Serial number/OEM ID",
  PTO_LETTER: "PTO Letter",
  CHANGE_ORDER: "Change Order",
  EXTENSION: "Extension",
  PERFORMANCE_REPORT: "Performance report",
  OTHER: "Other",
};

export const FILE_TYPE_ARRAY = Object.values(FILE_TYPES);

export const OEM_FILE_TYPES = ["Performance report", "Other"];

export const PROJECT_ALREADY_EXISTS_ERROR_MESSAGE = "Project already exists";

export const FILE_SIZE_10MB = 10 * 1024 * 1024;

export const SUPPORT_EMAIL = "solarbatterysavings@sdcommunitypower.org";
