import React from "react";
import FormInput from "@/components/FormInput";
import { Control, Controller } from "react-hook-form";
import { Box, Checkbox, Typography } from "@mui/material";
import BatteryModelsForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/BatteryModelsForm.tsx";
import FormAsyncSelect from "@/components/FormAsyncSelect";
import { getManufacturingBrandReq } from "@/api/userApi";
import { EmailPatternValidation } from "@/validators/emailPatternValidation.ts";

const SecondOemForm = ({
  control,
  isEdit = false,
}: {
  control: Control;
  isEdit?: boolean;
}) => {
  return (
    <Box sx={{ height: "100%", overflow: isEdit ? "initial" : "auto" }}>
      <FormAsyncSelect
        name={"manufactoringBrand"}
        control={control}
        label={"Manufacturing Brand"}
        placeholder={"Select manufacturer"}
        getItems={(searchValue) => getManufacturingBrandReq(searchValue)}
        itemsKey={"oems"}
        controlProps={{ rules: { required: "Manufacturing is required" } }}
      />
      <FormInput
        name={"contactName"}
        control={control}
        label={"Contact Name(s)"}
        placeholder={"John Smith"}
        controlProps={{ rules: { required: "Contact Name is required" } }}
      />
      <FormInput
        name={"contactEmail"}
        control={control}
        label={"Contact email(s)"}
        placeholder={"email@gmail.com"}
        controlProps={{
          rules: {
            required: "Contact email is required",
            ...EmailPatternValidation,
          },
        }}
      />
      <FormInput
        name={"contactPhone"}
        control={control}
        label={"Contact Phone"}
        placeholder={"(XXX) XXX-XXXX"}
      />
      <BatteryModelsForm control={control} />
      <FormInput
        name={"dermsList"}
        control={control}
        label={"If integrated with existing DERMs, please list here:"}
        placeholder={"DERMs..."}
      />
      <FormInput
        name={"apiIntegrations"}
        control={control}
        label={"Current API Integrations"}
        placeholder={"APIs, comma separated"}
      />
      <Controller
        name={"ieeeProtocol"}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box
              onChange={(value) => onChange(value)}
              sx={{
                cursor: "pointer",
                display: "flex",
                gap: "4px",
                marginBottom: "16px",
                alignItems: "center",
              }}
              onClick={() => onChange(!value)}
            >
              <Checkbox
                sx={{ color: "#0076CE" }}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
              />
              <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                The battery support IEEE 2030.5 protocol
              </Typography>
            </Box>
          );
        }}
      />
      <FormInput
        name={"maintainingTelemetryDataInterval"}
        control={control}
        label={
          "At what interval does the BESS Operator maintain telemetry data? "
        }
        placeholder={"e.g. 15 minutes"}
      />
      <FormInput
        name={"achInformation"}
        control={control}
        label={"ACH Information"}
        placeholder={"ACH Information"}
      />
    </Box>
  );
};

export default SecondOemForm;
