import React, { useEffect, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import SVGPagArrowLeft from "@/assets/icons/SVGPagArrowLeft.tsx";
import SVGPagArrowRight from "@/assets/icons/SVGPagArrowRight.tsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProjectsStore } from "@/store/projectsStore.ts";
import { StyledArrowButton } from "@/components/styled";

type Props = {
  ids: number[];
  getIds: (
    search?: string | undefined,
    filter?: string[] | undefined,
    sort?: string,
    pto?: string,
  ) => void;
  navigateTo: (id: number, searchParams: URLSearchParams) => void;
};

const ProjectsNavigation = ({ ids, getIds, navigateTo }: Props) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const searchValue = searchParams.get("search"); // Gets the 'search' query parameter
  const filters = searchParams.get("filter");
  const sort = searchParams.get("sort");
  const pto = searchParams.get("pto");

  const theme = useTheme();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("xl"));

  const currentIndex = useMemo(() => {
    return ids?.findIndex((projectId) => projectId === Number(id));
  }, [ids, id]);

  const nextProjectId =
    currentIndex !== -1 && currentIndex < ids?.length - 1
      ? ids?.[currentIndex + 1]
      : null;

  const prevProjectId = currentIndex > 0 ? ids?.[currentIndex - 1] : null;

  const handleNavigate = (projectId: number | null) => {
    if (projectId) {
      navigateTo(projectId, searchParams);
    }
  };

  useEffect(() => {
    getIds(searchValue ?? "", filters?.split(",") || [], sort ?? "", pto ?? "");
  }, [searchValue, filters, sort, pto]);

  return (
    <>
      {prevProjectId && (
        <StyledArrowButton
          sx={{
            left: !matchesDownLg ? "-70px" : 0,
            position: "absolute",
            top: "17px",
          }}
          onClick={() => handleNavigate(prevProjectId)}
          disabled={!prevProjectId}
        >
          <SVGPagArrowLeft />
        </StyledArrowButton>
      )}
      {nextProjectId && (
        <StyledArrowButton
          sx={{
            right: !matchesDownLg ? "-70px" : 0,
            position: "absolute",
            top: "17px",
          }}
          onClick={() => handleNavigate(nextProjectId)}
        >
          <SVGPagArrowRight />
        </StyledArrowButton>
      )}
    </>
  );
};

export default ProjectsNavigation;
