import React, { useEffect } from "react";
import Card from "@/components/Card";
import { Typography } from "@mui/material";
import AddNote from "@/pages/ProjectDetailsPage/components/NoteSection/AddNote.tsx";
import NoteList from "@/pages/ProjectDetailsPage/components/NoteSection/NoteList.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useParams } from "react-router-dom";
import { useNotesStore } from "@/store/noteStore.ts";

const NoteSection = () => {
  const { id } = useParams();
  const getNotes = useNotesStore((state) => state.getNotes);

  useEffect(() => {
    if (id) {
      getNotes(Number(id));
    }
  }, [id]);

  return (
    <Card sx={{ marginBottom: "20px" }}>
      <Typography variant={"cardHeader2"}>Notes</Typography>
      <AddNote />
      <NoteList />
    </Card>
  );
};

export default NoteSection;
