import { create } from "zustand";
import { updateArticleReq, deleteArticleReq } from "@/api/adminApi";
import { ArticleType } from "@/types";
import { ArticleForm } from "@/pages/NewsPage/components/EditArticle.tsx";
import { getArticlesReq } from "@/api/userApi";

type State = {
  news: ArticleType[];
};

type Actions = {
  getNews: (search: string) => void;
  createArticle: (newArticle: ArticleType) => void;
  updateArticle: (
    articleId: number,
    updatedArticle: ArticleForm,
  ) => Promise<void>;
  deleteArticle: (articleId: number) => Promise<void>;
};

export const useNewsStore = create<State & Actions>((set, get) => ({
  news: [],

  // Fetch news with search
  getNews: async (search) => {
    try {
      const response = await getArticlesReq(search);
      set(() => ({
        news: response?.data?.articles,
      }));
    } catch (e) {
      console.error(e);
    }
  },

  // Create article with optimistic update
  createArticle: (newArticle) => {
    // Optimistically add the new article to the state
    set((state) => ({
      news: [newArticle, ...state.news],
    }));
  },

  // Update article with optimistic update
  updateArticle: async (articleId, updatedArticle) => {
    const previousNews = get().news;

    // Optimistically update the article in the state
    set((state) => ({
      news: state.news.map((article) =>
        article.id === articleId ? { ...article, ...updatedArticle } : article,
      ),
    }));

    try {
      // Call API to update article
      await updateArticleReq(articleId, {
        title: updatedArticle?.title,
        content: updatedArticle?.content,
        dateStamp: updatedArticle?.dateStamp,
      });
    } catch (e) {
      console.error(e);
      // Revert the state if API call fails
      set(() => ({
        news: previousNews,
      }));
    }
  },

  // Delete article with optimistic update
  deleteArticle: async (articleId) => {
    const previousNews = get().news;

    // Optimistically remove the article from the state
    set((state) => ({
      news: state.news.filter((article) => article.id !== articleId),
    }));

    try {
      // Call API to delete article
      await deleteArticleReq(articleId);
    } catch (e) {
      console.error(e);
      // Revert the state if API call fails
      set(() => ({
        news: previousNews,
      }));
    }
  },
}));
