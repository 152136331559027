import { ProjectRecord, UserType } from "@/types";

export const allowUploadProject = (
  role: UserType["role"] | null,
  status: ProjectRecord["enrolmentStatus"],
) => {
  switch (role) {
    case "oem":
      return status !== "rejected";
    case "contractor":
      return status !== "archived";
    case "administrator":
      return true;
    default:
      return false;
  }
};
