import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import {
  FormProvider,
  useForm,
  useWatch,
  useFormContext,
} from "react-hook-form";
import FormInput from "@/components/FormInput";
import { UserType } from "@/types";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVGPlus from "@/assets/icons/SVGPlus.tsx";
import FirstUserForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/FirstUserForm.tsx";
import SecondContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondContractorForm.tsx";
import ThirdContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/ThirdContractorForm.tsx";
import { checkUserEmail, createUserReq } from "@/api/adminApi";
import SecondOemForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondOemForm.tsx";
import { useAdminStore } from "@/store/adminStore.ts";
import { AxiosError } from "axios";
import { validateEmail } from "@/utils/validateEmail.ts";
import { StyledRightActionButton } from "@/components/styled";
import { useErrorBoundary } from "@/libs/ErrorBoundary.tsx";

type Props = {
  handleChangePage: () => void;
};

const CreateUserModal = ({ handleChangePage }: Props) => {
  const handleError = useErrorBoundary();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const methods = useForm<Partial<UserType>>({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: { isValid },
  } = methods;

  const selectedRole = useWatch({ control, name: "role" })?.toLowerCase();

  const handleClose = () => {
    setOpen(false);
    reset();
    setStep(1);
  };

  const onSubmit = async (data: any) => {
    try {
      const emailError = await validateEmail(data.email);
      if (emailError !== true) {
        methods.setError("email", { message: emailError });
        return;
      }
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        const value = data[key as keyof Partial<UserType>];
        if (value !== undefined) {
          if (value instanceof File) {
            formData.append(key, value);
          } else if (key === "role") {
            formData.append(key, value?.toLowerCase());
          } else {
            if (key === "batteries" && Array.isArray(value)) {
              const transformedBatteries = value?.map((el) => ({
                ...el,
                batteryTypes: el?.batteryTypes?.join(", "),
              }));
              formData.append(key, JSON.stringify(transformedBatteries));
            } else {
              // exclude batteryModels
              if (key !== "batteryModels") {
                formData.append(key, value as string);
              }
            }
          }
        }
      });
      await createUserReq(formData);
      handleChangePage();
      handleClose();
    } catch (e: any) {
      handleError(e);
      console.error(e);
    }

    // handleClose();
  };

  const nextStep = async () => {
    const emailValue = getValues("email");
    const emailError = await validateEmail(emailValue ?? "");
    if (emailError === true) {
      setStep((prev) => prev + 1);
    } else {
      methods.setError("email", { message: emailError });
    }
  };
  const prevStep = () => setStep((prev) => prev - 1);

  useEffect(() => {
    setStep(1);
  }, [selectedRole]);

  const isLastStep = () => {
    if (selectedRole === "administrator" && step === 1) return true;
    if (selectedRole === "contractor" && step === 3) return true;
    if (selectedRole === "oem" && step === 2) return true;
    return false;
  };

  const isNextStepDisabled = () => {
    if (
      step === 1 &&
      (!getValues("fullName") || !getValues("email") || !getValues("role"))
    )
      return true;
    if (
      step === 2 &&
      selectedRole === "contractor" &&
      (!getValues("companyName") || !getValues("businessLicenseNumber"))
    )
      return true;
    return false;
  };

  const getTotalSteps = () => {
    if (selectedRole === "administrator") return 1;
    if (selectedRole === "contractor") return 3;
    if (selectedRole === "oem") return 2;
    return 1;
  };
  return (
    <>
      <Box>
        <StyledRightActionButton
          onClick={() => setOpen(true)}
          sx={{ gap: "8px", whiteSpace: "nowrap" }}
          variant={"contained"}
        >
          <SVGPlus />
          {!downSm && "Add Account"}
        </StyledRightActionButton>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        title={"Add Account"}
        subtitle={step > 1 ? `${step}/${getTotalSteps()} steps` : undefined}
      >
        <FormProvider {...methods}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              marginTop: "24px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {step === 1 && <FirstUserForm control={control} />}
            {step === 2 && selectedRole === "contractor" && (
              <SecondContractorForm control={control} />
            )}
            {step === 3 && selectedRole === "contractor" && (
              <ThirdContractorForm control={control} />
            )}
            {step === 2 && selectedRole === "oem" && (
              <SecondOemForm control={control} />
            )}
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                gap: "8px",
                width: "100%",
              }}
            >
              <Button
                variant={"outlined"}
                sx={{ width: "100%" }}
                onClick={() => (step === 1 ? handleClose() : prevStep())}
              >
                Back
              </Button>
              {!isLastStep() && (
                <Button
                  variant={"contained"}
                  sx={{ width: "100%" }}
                  onClick={nextStep}
                  disabled={!isValid || isNextStepDisabled()}
                >
                  {"Continue"}
                </Button>
              )}
              {isLastStep() && (
                <Button
                  variant={"contained"}
                  sx={{ width: "100%" }}
                  type={"submit"}
                  disabled={!isValid || isNextStepDisabled()}
                >
                  {"Send invite"}
                </Button>
              )}
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateUserModal;
