import axios from "@/libs/axios.ts";

export const getBatteryModelsReq = async (oem: string, searchValue: string) => {
  const params = {
    oem,
    search: searchValue,
  };
  return axios.get(`/battery/battery-models`, { params });
};

export const getBatteryListReq = async (oem: string, searchValue: string) => {
  const params = {
    oem,
    search: searchValue,
  };
  return axios.get(`/battery/list`, { params });
};

export const getBatteryTypesReq = async (searchValue: string) => {
  const params = {
    search: searchValue,
  };
  return axios.get(`/battery/battery-types`, { params });
};

export const getBatteryCapacityReq = async (batteryModel: string) => {
  return axios.get(`/battery/${batteryModel}`);
};
