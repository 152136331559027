export const calculateUsableBatteryCapacity = (
  usableCapacity: string | undefined,
  numberOfBatteries: string | undefined,
) => {
  const usableCapacityToNumber = Number(usableCapacity);
  const numberOfBatteriesToNumber = Number(numberOfBatteries);
  return usableCapacityToNumber && numberOfBatteriesToNumber
    ? `${usableCapacityToNumber * numberOfBatteriesToNumber}`
    : usableCapacity;
};
