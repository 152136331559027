import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StyledInput } from "@/components/styled";

const NewsDatePicker = ({ control }: { control: Control<any, any> }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const inputRef = useRef<HTMLElement | null>(null);
  return (
    <>
      <Box
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: (theme) => theme.palette.textGray1,
          paddingBottom: "2px",
          display: "flex",
        }}
      >
        Date stamp
      </Box>
      <Controller
        name={"dateStamp"}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box sx={{ position: "relative", width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%" }}
                  open={openDatePicker}
                  onClose={() => setOpenDatePicker(false)}
                  value={value ? dayjs(value) : null}
                  onChange={(date) => onChange(date ? dayjs(date) : "")}
                  slots={{
                    textField: StyledInput,
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenDatePicker(true),
                      inputRef,
                    },
                    popper: {
                      anchorEl: inputRef.current,
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          );
        }}
      />
    </>
  );
};

export default NewsDatePicker;
