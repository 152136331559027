import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import logo from "@/assets/logo.svg";
import React, { ReactNode } from "react";
import { useAuthStore } from "@/store/authStore.ts";
import Navigation from "@/components/Navigation";
import ProfileButton from "@/components/Header/ProfileButton.tsx";

const BoxHeaderInner = styled(Box)(({ theme }) => ({
  padding: "26px 80px",
  display: "flex",
  gap: "40px",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    padding: "24px",
  },
}));

const Header = ({ bottomSection }: { bottomSection?: ReactNode }) => {
  const isAuth = useAuthStore((state) => state.isAuth);
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ backgroundColor: "#003257", position: "relative" }}>
      <BoxHeaderInner>
        <Box
          sx={{
            display: "flex",
            gap: downMd ? "16px" : "40px",
            alignItems: "center",
            overflowX: "auto",
          }}
        >
          {downMd && isAuth && <Navigation />}
          <img style={{ ...(downMd && { width: "110px" }) }} src={logo} />
          {!downMd && isAuth && <Navigation />}
        </Box>
        {isAuth && <ProfileButton />}
      </BoxHeaderInner>
      {bottomSection}
    </Box>
  );
};

export default Header;
