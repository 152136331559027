import React, { useCallback, useEffect, useMemo } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import {
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledBadge,
  StyledIconButton,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import SVGTrashCan from "@/assets/icons/SVGTrashCan.tsx";
import TablePagination from "@/components/TablePagination";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import { FileRecord } from "@/types";
import { deleteFileReq } from "@/api/fileApi";
import FileTableItem from "@/components/FileTableItem";
import { useAuthStore } from "@/store/authStore.ts";
import { formatBytes } from "@/components/FormUploadInput";
import TableFilter from "@/components/TableFilter";
import { COMMON_SORT_ITEMS, UPLOADED_FILES_FILTER_ITEMS } from "@/constants";
import useTableFilter from "@/hooks/useTableFilter.ts";
import { downloadFile } from "@/utils/downloadFile.ts";
import SVGDownloadFile from "@/assets/icons/SVGDownloadFile.tsx";
import { getFileStorageBadgeVariant } from "@/utils/getFileStorageBadgeVariant.ts";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";
import CommonTableActions from "@/components/CommonTableActions";
import TableSort from "@/components/TableSort";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import { getFileTypeLabel } from "@/utils/getFileTypeLabel.ts";

function createData(
  id: number,
  fileName: string,
  fileSize: string,
  accountName: string | null,
  accountEmail: string | null,
  createdAt: string,
  status: string,
  url: string,
  fileType: string,
) {
  return {
    id,
    fileName,
    fileSize: formatBytes(Number(fileSize)),
    accountName,
    accountEmail,
    status,
    createdAt,
    url,
    fileType,
  };
}

const UserFilesManagementTable = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const fetchDataWithLoading = useFetchDataWithLoading();

  const role = useAuthStore((state) => state.role);

  const { userUploadedFiles, totalUserUploadedFiles } = useAdminStore(
    (state) => state.userUploadedFiles,
  );
  const getAllUsersUploadedFiles = useAdminStore(
    (state) => state.getAllUsersUploadedFiles,
  );
  const rows = useMemo(() => {
    return userUploadedFiles?.map(
      ({
        id,
        fileName,
        size,
        createdAt,
        status,
        url,
        accountName,
        accountEmail,
        type,
      }) =>
        createData(
          id,
          fileName,
          size,
          accountName,
          accountEmail,
          createdAt,
          status as any,
          url,
          type,
        ),
    );
  }, [userUploadedFiles]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalUserUploadedFiles);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { dateRangeValue, handleDateRangeChange } = useDateRange();
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);

  const getFilesReq = useCallback(() => {
    fetchDataWithLoading(getAllUsersUploadedFiles, [
      page + 1,
      rowsPerPage,
      searchValue,
      sort,
      filters,
      dateRangeValue,
    ]);
  }, [
    fetchDataWithLoading,
    getAllUsersUploadedFiles,
    page,
    rowsPerPage,
    searchValue,
    sort,
    filters,
    dateRangeValue,
  ]);

  useEffect(() => {
    getFilesReq();
  }, [getFilesReq]);
  const deleteFile = async (fileId: FileRecord["id"]) => {
    try {
      await deleteFileReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <StyledTableCard sx={{ width: "100%" }}>
      <CommonTableActions
        title={"Users files"}
        onSearch={handleDebounceSearchValue}
        onDateRange={handleDateRangeChange}
        filter={
          <TableFilter
            placeholder={"Statuses"}
            items={UPLOADED_FILES_FILTER_ITEMS}
            setFilterValues={handleFilterChange}
          />
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
      />
      <TableContainer>
        <StyledTable sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>File name</StyledTableCell>
              <StyledTableCell>File size</StyledTableCell>
              <StyledTableCell>File type</StyledTableCell>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>Account email</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Upload date</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  rowId={row.id}
                  key={row.id}
                  title={row.fileName}
                  fileName={row.fileName}
                  firstSub={getFileTypeLabel(row.fileType ?? "")}
                  secondSubSx={{ maxWidth: "185px" }}
                  secondSub={
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getFileStorageBadgeVariant(row.status as any)}
                    >
                      {replaceUnderscoresWithSpaces(
                        capitalizeFirstLetter(row.status),
                      )}
                    </StyledBadge>
                  }
                >
                  <CellWithHeader header={"File size"}>
                    {row.fileSize}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Name"}>
                    {row.accountName}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Email"}>
                    {row.accountEmail}
                  </CellWithHeader>
                  <CellWithHeader header={"Upload date"}>
                    {formatDate(row.createdAt)}
                  </CellWithHeader>
                  <StyledRowActionsWrapperBox>
                    <StyledIconButton
                      onClick={() => {
                        downloadFile(`file/${row.id}`, row.fileName);
                      }}
                      variant={downSm ? "outlined" : "text"}
                      sx={{ ...(downSm && { width: "100%" }) }}
                    >
                      {!downSm && <SVGDownloadFile />}
                      {downSm && "Download"}
                    </StyledIconButton>
                    {row.status !== "deleted" && role === "administrator" && (
                      <StyledIconButton
                        onClick={() => {
                          deleteFile(row.id);
                        }}
                        sx={{ ...(downSm && { width: "100%" }) }}
                        variant={downSm ? "dangerOutlined" : "text"}
                      >
                        {!downSm && <SVGTrashCan />}
                        {downSm && "Delete"}
                      </StyledIconButton>
                    )}
                  </StyledRowActionsWrapperBox>
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <FileTableItem
                      url={`file/${row.id}`}
                      fileName={row.fileName}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row.fileSize}</StyledTableCell>
                  <StyledTableCell>
                    {getFileTypeLabel(row.fileType)}
                  </StyledTableCell>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.accountEmail}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getFileStorageBadgeVariant(row.status as any)}
                    >
                      {replaceUnderscoresWithSpaces(
                        capitalizeFirstLetter(row.status),
                      )}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>{formatDate(row.createdAt)}</StyledTableCell>
                  <StyledTableCell>
                    <StyledRowActionsWrapperBox>
                      <StyledIconButton
                        onClick={() => {
                          downloadFile(`file/${row.id}`, row.fileName);
                        }}
                      >
                        <SVGDownloadFile />
                      </StyledIconButton>
                      {row.status !== "deleted" && role === "administrator" && (
                        <StyledIconButton
                          onClick={() => {
                            deleteFile(row.id);
                          }}
                        >
                          <SVGTrashCan />
                        </StyledIconButton>
                      )}
                    </StyledRowActionsWrapperBox>
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalUserUploadedFiles}
      />
    </StyledTableCard>
  );
};

export default UserFilesManagementTable;
