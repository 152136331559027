import { checkCustomerName } from "@/api/projectsApi";

export const validateCustomerName = async (customerName: string) => {
  try {
    await checkCustomerName(customerName);
    return true;
  } catch (error: any) {
    return error?.response?.data?.message;
  }
};
