import React from "react";
import { Box, Checkbox } from "@mui/material";

type Props = {
  isPTOLetterFilter: boolean;
  togglePTOLetterFilter: () => void;
};

const PtoLetterCheckbox = ({
  isPTOLetterFilter,
  togglePTOLetterFilter,
}: Props) => {
  return (
    <Box
      onClick={() => togglePTOLetterFilter()}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 24px 10px 10px",
        cursor: "pointer",
      }}
    >
      <Checkbox
        checked={isPTOLetterFilter}
        onClick={(event) => event.stopPropagation()}
        onChange={() => togglePTOLetterFilter()}
      />{" "}
      PTO Letter Submitted{" "}
    </Box>
  );
};

export default PtoLetterCheckbox;
