import React, { useCallback, useMemo, useState } from "react";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import { INCENTIVE_STATUS, INCENTIVE_STATUS_ITEMS } from "@/constants";
import { getFileStorageBadgeVariant } from "@/utils/getIncentiveBadgeVariant.ts";
import { ProjectRecord } from "@/types";
import { useAuthStore } from "@/store/authStore.ts";
import { updateProject } from "@/api/projectsApi";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Modal as MuiModal } from "@mui/material";
import NextStepModal from "@/pages/ContractorDashboardPage/components/NextStepModal.tsx";
import { formatCurrency } from "@/utils/formatCurrency.ts";

type Props = {
  tooltipContent: string | null;
  incentiveStatus: ProjectRecord["incentiveStatus"];
  projectId: ProjectRecord["id"];
  getProjectsReq: () => void;
  upfrontIncentivePayment: ProjectRecord["upfrontIncentivePayment"];
  truncateBadge?: boolean;
};

const IncentiveStatusSelect = ({
  tooltipContent,
  incentiveStatus,
  projectId,
  getProjectsReq,
  upfrontIncentivePayment,
  truncateBadge = false,
}: Props) => {
  const [changedIncentiveStatus, setChangedIncentiveStatus] = useState(
    "" as INCENTIVE_STATUS,
  );
  const [pendingIncentiveStatus, setPendingIncentiveStatus] = useState<
    INCENTIVE_STATUS | ""
  >("");
  const [openBudgetManualModal, setOpenBudgetManualModal] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<{
    message: string;
    severity: "error" | "success";
  }>({
    message: "",
    severity: "error",
  });

  const handleClose = () => {
    setHasNotification(false);
  };

  const handleCloseBudgetAutoModal = () => {
    setChangedIncentiveStatus("" as INCENTIVE_STATUS);
  };

  const handleCloseBudgetManualModal = () => {
    setOpenBudgetManualModal(false);
    setPendingIncentiveStatus("");
  };

  const handleConfirmManualUpdate = async () => {
    if (pendingIncentiveStatus) {
      await updateIncentiveProjectStatus(projectId, pendingIncentiveStatus);
      handleCloseBudgetManualModal();
    }
  };

  const role = useAuthStore((state) => state.role);

  const updateIncentiveProjectStatus = async (
    projectId: ProjectRecord["id"],
    value: ProjectRecord["incentiveStatus"],
  ) => {
    try {
      const formData = new FormData();
      Object.keys({ incentiveStatus: value }).forEach((key) => {
        formData.append(key, value as string);
      });

      const response = await updateProject(projectId, formData);
      getProjectsReq();
      setHasNotification(true);
      setNotificationMessage({
        message: response?.data?.project?.message,
        severity: "success",
      });
      return response;
    } catch (e: any) {
      setHasNotification(true);
      setNotificationMessage({
        message: e?.response?.data?.error,
        severity: e?.response?.data?.success ? "success" : "error",
      });
      console.error(e);
      throw e;
    } finally {
      handleCloseBudgetAutoModal();
    }
  };

  const handleChangeStatus = async (
    projectId: ProjectRecord["id"],
    value: ProjectRecord["incentiveStatus"],
  ) => {
    switch (incentiveStatus) {
      case INCENTIVE_STATUS.RESERVED: {
        if (value === INCENTIVE_STATUS.PAID) {
          setChangedIncentiveStatus(value);
        } else if (
          value === INCENTIVE_STATUS.PENDING ||
          value === INCENTIVE_STATUS.NO_HELD ||
          value === INCENTIVE_STATUS.CANCELLED
        ) {
          setOpenBudgetManualModal(true);
          setPendingIncentiveStatus(value);
        } else {
          await updateIncentiveProjectStatus(projectId, value);
        }
        break;
      }
      case INCENTIVE_STATUS.PENDING: {
        if (value === INCENTIVE_STATUS.RESERVED) {
          setChangedIncentiveStatus(value);
        } else {
          await updateIncentiveProjectStatus(projectId, value);
        }
        break;
      }
      case INCENTIVE_STATUS.EXTENDED: {
        if (value === INCENTIVE_STATUS.RESERVED) {
          setOpenBudgetManualModal(true);
          setPendingIncentiveStatus(value);
        } else if (value === INCENTIVE_STATUS.PAID) {
          setChangedIncentiveStatus(value);
        } else {
          await updateIncentiveProjectStatus(projectId, value);
        }
        break;
      }
      case INCENTIVE_STATUS.PAID: {
        if (value === INCENTIVE_STATUS.RESERVED) {
          setOpenBudgetManualModal(true);
          setPendingIncentiveStatus(value);
        } else {
          await updateIncentiveProjectStatus(projectId, value);
        }
        break;
      }
      default:
        await updateIncentiveProjectStatus(projectId, value);
    }
  };

  const handleNextStep = async () => {
    try {
      return await updateIncentiveProjectStatus(
        projectId,
        changedIncentiveStatus,
      );
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      handleCloseBudgetAutoModal();
    }
  };

  const getFromToStatusLabel = useCallback(
    (incentiveStatus: INCENTIVE_STATUS | undefined) => {
      switch (incentiveStatus) {
        case INCENTIVE_STATUS.PENDING:
          return "Remaining";
        case INCENTIVE_STATUS.RESERVED:
        case INCENTIVE_STATUS.EXTENDED:
          return "Reserved";
        case INCENTIVE_STATUS.PAID: {
          return "Paid";
        }
        default:
          return "";
      }
    },
    [incentiveStatus],
  );

  const statusLabelTo = getFromToStatusLabel(changedIncentiveStatus);
  const statusLabelFrom = getFromToStatusLabel(incentiveStatus);

  const isStatusEnabled = (
    currentStatus: INCENTIVE_STATUS,
    validStatuses: INCENTIVE_STATUS[],
  ) => validStatuses.includes(currentStatus);

  const incentiveSelectItems = useMemo(() => {
    if (incentiveStatus) {
      const itemsWithDisabledState = INCENTIVE_STATUS_ITEMS.map((el) => {
        const { value } = el;

        const isDisabled = (() => {
          switch (value) {
            case INCENTIVE_STATUS.PENDING:
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.PENDING,
                INCENTIVE_STATUS.NO_HELD,
              ]);
            case INCENTIVE_STATUS.PAID: {
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.EXTENDED,
                INCENTIVE_STATUS.PAID,
              ]);
            }
            case INCENTIVE_STATUS.EXTENDED:
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.EXTENDED,
              ]);
            case INCENTIVE_STATUS.NO_HELD:
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.NO_HELD,
                INCENTIVE_STATUS.CANCELLED,
              ]);
            case INCENTIVE_STATUS.RESERVED:
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.PAID,
                INCENTIVE_STATUS.NO_HELD,
                INCENTIVE_STATUS.EXTENDED,
                INCENTIVE_STATUS.PENDING,
              ]);
            case INCENTIVE_STATUS.CANCELLED:
              return !isStatusEnabled(incentiveStatus, [
                INCENTIVE_STATUS.RESERVED,
                INCENTIVE_STATUS.NO_HELD,
                INCENTIVE_STATUS.EXTENDED,
                INCENTIVE_STATUS.PENDING,
              ]);
            default:
              return false;
          }
        })();

        return { ...el, disabled: isDisabled };
      });
      return itemsWithDisabledState.sort(
        (a, b) => Number(a.disabled) - Number(b.disabled),
      );
    }
  }, [incentiveStatus]);

  return (
    <>
      <BadgeSelect
        tooltipContent={tooltipContent}
        readOnly={role !== "administrator"}
        currentSelectedItem={incentiveStatus as string}
        items={incentiveSelectItems ?? []}
        onSelectItem={(value) =>
          handleChangeStatus(projectId, value as INCENTIVE_STATUS)
        }
        badgeVariantFn={getFileStorageBadgeVariant}
        truncateBadge={truncateBadge}
      />
      {hasNotification && (
        <Snackbar
          open={hasNotification}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={notificationMessage?.severity}>
            {notificationMessage?.message}
          </Alert>
        </Snackbar>
      )}
      <MuiModal
        open={!!changedIncentiveStatus}
        onClose={handleCloseBudgetAutoModal}
      >
        <>
          <NextStepModal
            onClick={handleNextStep}
            onClose={handleCloseBudgetAutoModal}
            title={`This status change will lead to a Budget update`}
            subtitle={`${formatCurrency(Number(upfrontIncentivePayment) / 100)} will be transferred from ${statusLabelFrom} to ${statusLabelTo}`}
            buttonText={"Continue"}
          />
        </>
      </MuiModal>
      <MuiModal
        open={openBudgetManualModal}
        onClose={handleCloseBudgetManualModal}
      >
        <>
          <NextStepModal
            onClose={handleCloseBudgetManualModal}
            title={`This action will affect the budget status.`}
            subtitle={`After updating the status, make sure to adjust the budget amount manually`}
            buttonText={"Continue"}
            onClick={handleConfirmManualUpdate}
          />
        </>
      </MuiModal>
    </>
  );
};

export default IncentiveStatusSelect;
