import { ProjectRecord } from "@/types";
import { CONTRACTOR_ENROLLMENT_STATUS_ITEMS } from "@/constants";

export const getEnrollmentStatusItems = (
  enrollmentStatus: ProjectRecord["enrolmentStatus"],
) => {
  return CONTRACTOR_ENROLLMENT_STATUS_ITEMS.map((item) => {
    if (enrollmentStatus === "rejected") {
      return { ...item, disabled: item.value !== "contractor_updated" };
    }
    return item;
  });
};

export const getEnrollmentStatusChangeAllowed = (
  enrollmentStatus: ProjectRecord["enrolmentStatus"],
) => {
  return !["correction_needed", "rejected"].includes(
    enrollmentStatus as string,
  );
};
