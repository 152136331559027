import { UserType } from "@/types";

export const getStatusTooltipContent = (
  updatedAt: string | null,
  role: UserType["role"] | null,
) => {
  return role === "administrator" && !!updatedAt
    ? `Updated: ${updatedAt}`
    : null;
};
