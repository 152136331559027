import axios from "@/libs/axios.ts";

export const completeTrainingVideoReq = async (body: {
  trainingCompleted?: boolean;
}) => axios.post(`/user/training-completed`, body);

export const getMeReq = async () => axios.get(`/user/me`);
export const updateMeReq = async (body: FormData) =>
  axios.patch(`/user/update-account`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getMapAuthReq = async () => {
  return axios.get(`/user/arcgis`);
};

export const getBatteryOemsReq = async (searchValue: string) => {
  const params = {
    search: searchValue,
  };
  return axios.get(`/user/oems`, { params });
};

export const getManufacturingBrandReq = async (searchValue: string) => {
  const params = {
    search: searchValue,
  };
  return axios.get(`/user/manufacturing-brand`, { params });
};

export const getContractorsReq = async (searchValue: string) => {
  const params = {
    search: searchValue,
  };
  return axios.get(`/user/contractors`, { params });
};
export const deleteAccountReq = async () => {
  return axios.delete(`/user/delete-account`);
};

export const getArticlesReq = async (search: string) => {
  const params = {
    sort: "createdAtDsc",
    search,
  };
  return axios.get(`/user/article`, { params });
};
