import create from "zustand";
import {
  getIsApplicationsActiveReq,
  updateIsApplicationsActiveReq,
} from "@/api/adminApi";

interface ApplicationState {
  isActive: boolean | null;
  fetchIsActive: () => void;
  toggleNewProjectApplication: () => void;
}

export const useApplicationStore = create<ApplicationState>(
  (set, getState) => ({
    isActive: null,

    fetchIsActive: async () => {
      try {
        const response = await getIsApplicationsActiveReq();
        set({ isActive: response.data?.globalSetting?.[0]?.value });
      } catch (e) {
        console.error("Error fetching application state", e);
      }
    },

    toggleNewProjectApplication: async () => {
      try {
        const { isActive } = getState();
        await updateIsApplicationsActiveReq({ value: !isActive });
        set({ isActive: !isActive });
      } catch (e) {
        console.error("Error toggling application state", e);
      }
    },
  }),
);
