import React from "react";
import Card from "@/components/Card";
import { Box, Typography } from "@mui/material";
import { StyledSwitch } from "@/components/styled";
import { useApplicationStore } from "@/store/applicationStore.ts";

const NewProjectApplicationsToggle = () => {
  const isActive = useApplicationStore((state) => state.isActive);
  const toggleNewProjectApplication = useApplicationStore(
    (state) => state.toggleNewProjectApplication,
  );

  const toggleLabel = isActive
    ? "Enable New Project Applications"
    : "Disable New Project Applications";
  return (
    <Card sx={{ marginBottom: "20px" }} title={""}>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <StyledSwitch
          variant={"first"}
          checked={!!isActive}
          onChange={toggleNewProjectApplication}
        />
        <Typography variant={"helperText"} style={{ fontSize: "16px" }}>
          {toggleLabel}
        </Typography>
      </Box>
    </Card>
  );
};

export default NewProjectApplicationsToggle;
