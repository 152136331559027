import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (dateString: string) => {
  return dayjs(dateString)
    .tz("America/Los_Angeles")
    .format("MMMM D, YYYY h:mm a");
};

export const shortFormatDate = (dateString: string) => {
  return dayjs(dateString).tz("America/Los_Angeles").format("MM/DD/YYYY");
};

export const shortFormatDateTimezoneFree = (dateString: string) => {
  return dayjs(dateString).format("MM/DD/YYYY");
};
