const labelTypeMap: Record<string, string> = {
  contract: "Contract",
  participantEnrollment: "Participant Enrollment",
  serialNumberOemId: "Serial number/OEM ID",
  ptoLetter: "PTO Letter",
  changeOrder: "Change Order",
  extension: "Extension",
  other: "Other",
  systemSizingTool: "System Sizing Tool",
  performanceReport: "Performance report",
};

export const getFileTypeLabel = (type: string) => {
  return labelTypeMap[type] || type;
};
