import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { StyledBadge, StyledMenu, StyledTooltip } from "@/components/styled";
import { Box, MenuItem, Typography } from "@mui/material";
import SvgSimpleCheckMark from "@/assets/icons/SVGSimpleCheckMark.tsx";
import SvgSelectSign from "@/assets/icons/SVGSelectSign.tsx";

type Item = {
  value: string;
  label: string;
  color: string;
  disabled?: boolean;
};

type Props = {
  tooltipContent?: string | null;
  readOnly?: boolean;
  currentSelectedItem: string;
  items: Item[];
  onSelectItem?: (value: string) => Promise<any>;
  badgeVariantFn: (value: any) => any;
  truncateBadge?: boolean;
};

const BadgeSelect = ({
  tooltipContent = "",
  readOnly = false,
  currentSelectedItem,
  items,
  onSelectItem,
  badgeVariantFn,
  truncateBadge = false,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<string>(currentSelectedItem);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!readOnly) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = async (value: string) => {
    try {
      if (value !== currentSelectedItem) {
        const response = await onSelectItem?.(value);
        if (response) {
          setSelectedItem(value);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      handleClose();
    }
  };

  const isSelected = (value: string) => selectedItem === value;

  useEffect(() => {
    setSelectedItem(currentSelectedItem);
  }, [currentSelectedItem]);

  const selectedLabel = items.find(
    (item) => item.value === selectedItem,
  )?.label;

  return (
    <>
      <StyledTooltip
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -5],
                },
              },
            ],
          },
        }}
        title={tooltipContent}
      >
        <StyledBadge
          onClick={handleClick}
          {...badgeVariantFn(selectedItem)}
          sx={{
            display: "inline-flex",
            textWrap: "nowrap",
            ...(!readOnly && { cursor: "pointer" }),
            gap: "4px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              ...(truncateBadge && {
                maxWidth: "50px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }),
            }}
          >
            {capitalizeFirstLetter(selectedLabel ?? selectedItem ?? "")}
          </Box>
          {!readOnly && <SvgSelectSign />}
        </StyledBadge>
      </StyledTooltip>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((el) => (
          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "240px",
            }}
            disabled={el?.disabled}
            key={el.value}
            onClick={() => handleClickItem(el.value)}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                sx={{
                  width: "14px",
                  height: "14px",
                  backgroundColor: el.color,
                  borderRadius: "99px",
                }}
              />
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600, color: "#4E4B48" }}
              >
                {capitalizeFirstLetter(el.label ?? "")}
              </Typography>
            </Box>
            {isSelected(el.value) && <SvgSimpleCheckMark color={"#0076CE"} />}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default BadgeSelect;
